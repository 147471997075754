import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="হাত ঘুরিয়ে আরবী হরফ লিখি, সাথে শিখি দুইটা করে শব্দ (বই) " mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  হাত ঘুরিয়ে আরবী হরফ লিখি, সাথে শিখি দুইটা করে শব্দ (বই)
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/arabic',
        name: 'আরবী'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <p>{`আরবী বর্ণ বা হরফের সাথে শিশুর প্রথম পরিচয় হোক আনন্দঘন। হরফ শিখার সাথে সাথে জানুক না দুই তিনটা শব্দ। এতে অন্যান্য হরফের সাথেও পরিচয় হবে দৃঢ় ভাবে, ইন শা আল্লাহ।`}</p>
      <p>{`আর তাই GoodWord প্রকাশনীর একটি বই এর সহযোগীতা নিয়ে ‘মাদ্রাসাতুল ইল্‌ম’ তৈরি করেছে এই বইটি। বইটি এখনই প্রিন্ট করে দিতে পারেন আপনার শিশুর হাতে।`}</p>
      <Box textAlign="center" mdxType="Box">
  <a href="https://drive.google.com/file/d/1gaoLaVodYnTDk8MHgHknUBjPP-F9bTK7/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">ডাউনলোড</Button>
  </a>
      </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      